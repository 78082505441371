var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("common.commune", false, "Comuna"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'common.commune',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('common.add_commune'),expression:"'common.add_commune'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'common.commune',
            false,
            'Comuna'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-commune-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.communes,"fields":_vm.communesFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('common.change_commune'),expression:"'common.change_commune'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'common.commune',
            false,
            'Comuna'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-commune-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('common.delete_commune'),expression:"'common.delete_commune'"}],attrs:{"click_button":() => _vm.askForDeleteCommune(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'common.commune',
            false,
            'Comuna'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-commune-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'common.commune',
            false,
            'Comuna'
          )}`,"size":"lg","hide-footer":""}},[_c('CommuneForm',{attrs:{"Commune":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedCommune}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-commune-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('common.commune', false, 'Comuna')}`,"size":"lg"}},[_c('CommuneForm',{on:{"created":_vm.slotCreatedCommune}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }