<template>
  <div>
    <div class="mx-2">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->
      <h3 class="mt-5">
        {{ $getVisibleNames("common.commune", false, "Comuna") }}
        <AlternativeNameButton
          :table_name="'common.commune'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'common.add_commune'"
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'common.commune',
              false,
              'Comuna'
            )}`"
            :text_button="`Agregar`"
            :click_button="() => $bvModal.show(`new-commune-modal`)"
            :icon="'plus'"
            :variant="'primary'"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'common.add_commune'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-commune-modal`)"
          >
            Agregar
            {{ $getVisibleNames("common.commune", false, "Comuna") }}
          </button> -->
        </div>
      </div>
      <GenericBTable
        :items="communes"
        :fields="communesFields"
        :filter="input_search"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            v-can="'common.change_commune'"
            :tooltip_text="`Editar ${$getVisibleNames(
              'common.commune',
              false,
              'Comuna'
            )}`"
            :click_button="
              () => $bvModal.show(`edit-commune-modal-${row.item.id}`)
            "
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            v-can="'common.change_commune'"
            @click="$bvModal.show(`edit-commune-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames('common.commune', false, 'Comuna')}`
            "
          ></button-edit> -->
          <ButtonSmall
            v-can="'common.delete_commune'"
            :click_button="() => askForDeleteCommune(row.item.id)"
            :tooltip_text="`Eliminar ${$getVisibleNames(
              'common.commune',
              false,
              'Comuna'
            )}`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            v-can="'common.delete_commune'"
            @click="askForDeleteCommune(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames('common.commune', false, 'Comuna')}`
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-commune-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'common.commune',
              false,
              'Comuna'
            )}`"
            size="lg"
            hide-footer
          >
            <CommuneForm
              :Commune="row.item"
              @updated="slotUpdatedCommune"
              :show_title="false"
            ></CommuneForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-commune-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames('common.commune', false, 'Comuna')}`"
      size="lg"
    >
      <CommuneForm @created="slotCreatedCommune"></CommuneForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CommuneView",
  components: {
    CommuneForm: () =>
      import("@/components/mesh/CurricularResources/Commune/CommuneForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  props: {
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      input_search: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      communes: names.COMMUNES,
      regions: names.REGIONS,
      countries: names.COUNTRIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("common.add_commune");
      if (has_permission) return has_permission;
      else return false;
    },
    communesFields() {
      if (!this.allows_crud)
        return this.communes_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.communes_fields;
    },
    // breadcrumb_items() {
    //   return [
    //     { text: "Definiciones Organizacionales", active: true },
    //     {
    //       text: this.$getVisibleNames("common.commune", false, "Comuna"),
    //       active: true,
    //     },
    //   ];
    // },
    communes_fields() {
      return [
        {
          key: "order",
          label: "orden",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: false,
        },
        {
          key: "name",
          label: String(
            this.$getVisibleNames("common.commune", true, "Comunas")
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "region",
          label: this.$getVisibleNames("common.region", true, "Región"),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
          formatter: (value) => this.getRegionName(value),
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(String(row.name), this.input_search) ||
        this.$filtered(row.order, this.input_search) ||
        this.$filtered(this.getRegionName(row.region), this.input_search)
      ) {
        return true;
      } else {
        false;
      }
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getRegionName(region_id) {
      const region = this.regions.find((x) => x.id == region_id);
      return region ? region.name : "";
    },
    askForDeleteCommune(commune_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "common.commune",
          false,
          "Comuna"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_COMMUNE, commune_id).then(() => {
            toast(
              String(
                this.$getVisibleNames("common.commune", false, "Comuna") +
                  " eliminada."
              )
            );
          });
        }
      });
    },
    slotCreatedCommune() {
      this.$bvModal.hide("new-commune-modal");
    },
    slotUpdatedCommune(commune) {
      this.$bvModal.hide(`edit-commune-modal-${commune.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_COUNTRIES);
      this.$store.dispatch(names.FETCH_REGIONS);
      this.$store.dispatch(names.FETCH_COMMUNES);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-commune {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
</style>